<template>
	<layouts-identify :buttonBack="{ path: '/identify' }">
		<div class="pt-7 pb-4">
			<h3 class="ls-tight fw-bolder h3">Ingresá tu número de teléfono</h3>
		</div>
		<form class="vstack gap-5" @submit.prevent="submit">
			<common-form-input-phone v-model="phone" title="Teléfono" placeholder="Ingresá tu número telefónico" :validation="validation.phone" :focus="true" />
			<div class="text-center" v-if="validationExternal.length > 0">
				<div class="invalid-feedback d-block" v-for="(error, index) in validationExternal" v-bind:key="index">{{ error }}</div>
			</div>
			<div>
				<button type="submit" class="btn btn-dark d-flex align-items-center w-100" :disabled="submitting">
					<div class="flex-fill me-n5">Continuar</div>
					<span class="icon icon-sm ps-2">
						<i class="bi bi-chevron-right"></i>
					</span>
				</button>
			</div>
		</form>
	</layouts-identify>
</template>

<script>
	import { reactive, toRefs } from 'vue';
	import router from '@/router';
	import useVuelidate from '@vuelidate/core';
	import { helpers } from '@vuelidate/validators';
	import { isValidPhoneNumber, parsePhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';
	import composableForm from '@/composables/form';
	import UsersRepository from '@/repositories/UsersRepository';

	export default {
		setup() {
			const { submitting, validationExternal, catchError } = composableForm();

			const form = reactive({
				phone: ''
			});

			const validatePhone = (value) => {
				return isValidPhoneNumber(value);
			}

			const validatePhoneMessage = ({ $model }) => {
				var message = null;

				switch(validatePhoneNumberLength($model)) {
					case 'NOT_A_NUMBER':
						message = 'Debe ser numérico';
						break;
					case 'INVALID_COUNTRY':
						message = 'Debe incluir el código de país, incluyendo el signo +';
						break;
					case 'TOO_SHORT':
						message = 'Es demasiado corto';
						break;
					case 'INVALID_LENGTH':
						message = 'Contiene una longitud inválida';
						break;
					case 'TOO_LONG':
						message = 'Es demasiado largo';
						break;
					default:
						message = 'Es inválido';
						break;
				}

				return message;
			}

			const validation = useVuelidate({
				phone: {
					validatePhone: helpers.withMessage(validatePhoneMessage, validatePhone)
				}
			}, form);

			async function submit() {
				validationExternal.value = [];
				if(!await validation.value.$validate()) return;

				submitting.value = true;
				UsersRepository.identify({
					type: 'phone',
					value: parsePhoneNumber(form.phone).number
				}).then((response) => {
					var query = { target_type: 'phone', target: parsePhoneNumber(form.phone).number };

					if(response.data.exists) router.push({ name: 'identify.verification', query: query });
					else router.push({ name: 'identify.signin', query: query });
				}).catch(catchError);
			}

			return { ...toRefs(form), validation, validationExternal, submitting, submit };
		}
	}
</script>